// begin=====================================
export const FIELD_TYPE_NONE = 0; //空类型
export const FIELD_TYPE_TEXT_ZH = 1; //文本
export const FIELD_TYPE_LONG_TEXT_ZH = 2; //长文本
export const FIELD_TYPE_CATEGORY_TEXT = 3; //分类文本
export const FIELD_TYPE_NUM = 4; //数值
export const FIELD_TYPE_DATETIME = 5; //日期时间
export const FIELD_TYPE_URL = 6; //链接
export const FIELD_TYPE_TOKENIZE_RESULT_ZH = 7; //分词结果
export const FIELD_TYPE_TOKENIZE_RESULT_EN = 10001; //分词（英文）结果
export const FIELD_TYPE_ARRAY = 8; //数组
export const FIELD_TYPE_CAT_ARRAY = 9; //类别数组
export const FIELD_TYPE_OBJECT = 10; //对象
export const FIELD_TYPE_VECTORIZE_RESULT = 11; //向量化结果
export const FIELD_TYPE_EVENTS = 12; //向量化结果
export const FIELD_TYPE_TEXT_EN = 1001; //文本（英文）
export const FIELD_TYPE_LONG_TEXT_EN = 1002; //长文本（英文）
export const FIELD_TYPE_CONST = {
  FIELD_TYPE_NONE,
  FIELD_TYPE_TEXT_ZH,
  FIELD_TYPE_LONG_TEXT_ZH,
  FIELD_TYPE_CATEGORY_TEXT,
  FIELD_TYPE_NUM,
  FIELD_TYPE_DATETIME,
  FIELD_TYPE_URL,
  FIELD_TYPE_TOKENIZE_RESULT_ZH,
  FIELD_TYPE_TOKENIZE_RESULT_EN,
  FIELD_TYPE_ARRAY,
  FIELD_TYPE_CAT_ARRAY,
  FIELD_TYPE_OBJECT,
  FIELD_TYPE_VECTORIZE_RESULT,
  FIELD_TYPE_EVENTS,
  FIELD_TYPE_TEXT_EN,
  FIELD_TYPE_LONG_TEXT_EN
};
// ===========字段类型常量end=====================================
// 分析模块：module的配置信息
const moduleInfo = {
  //===========================输入输出模块===============================
  readTextLibraryModule: {
    label: "读取文本库",
    icon: "icon-st-transport",
    ifPersistent: false,
    persistent: true,
    isValid: false,
    moduleStatus: 0,
    progress: 0,
    input: null,
    configs: {
      textLibraryId: {
        label: "读入文本库",
        name: "text_library_id",
        source: "getTextLibraryList",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      }
    },
    output: {
      textLibrary: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: "getFieldsOfTextLibrary",
      changesOfFields: null
    }
  },
  saveAsTextLibraryModule: {
    icon: "icon-antOutline-save",
    label: "输出文本库",
    ifPersistent: false,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    configs: {
      textLibraryName: {
        label: "新文本库名",
        name: "text_library_name",
        value: null,
        showOnDesignView: true,
        componentType: "lineTextInput"
      },
      parentFolderId: {
        label: "父文件夹",
        name: "parent_folder_id",
        value: 0,
        showOnDesignView: false,
        componentType: "folderSelect",
        source: "getParentFolderIdOfSaveAsTextLibraryModule",
        defaultProps: {
          children: "children",
          label: "name"
        }
      },
      ifCover: {
        label: "是否允许覆盖文本库",
        name: "if_cover",
        value: true,
        showOnDesignView: true,
        componentType: "radio",
        source: "getIfCoverOptions"
      }
    },
    output: null,
    baseInput: "records",
    fields: {
      data: null,
      invisible: null,
      source: "getFieldsOfsaveAsTextLibraryModule",
      changesOfFields: null
    }
  },
  // ===========================处理模块================================
  // =============================分词
  tokenize: {
    label: "分词",
    icon: "icon-a-md-translateCopy",
    ifPersistent: true,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    //-----------配置页面信息
    configs: {
      field: {
        label: "待分词字段",
        name: "field",
        source: "getSelectableAnalysisFields",
        value: null,
        showOnDesignView: true,

        componentType: "singleSelect"
      },
      tokenizeMethod: {
        label: "分词方法",
        name: "tokenize_method",
        source: "getTokenizeMethodOptions",
        value: null,
        showOnDesignView: true,
        componentType: "radio"
      },
      tokenizeGranularity: {
        label: "分词粒度",
        name: "tokenize_granularity",
        source: "getTokenizeGranularityOptions",
        value: null,
        showOnDesignView: true,
        componentType: "radio"
      },
      tokenizeDictionaryObjectId: {
        label: "分词词典",
        name: "tokenize_dictionary_object_id",
        value: '61a9d227dd3768d580a64dff',
        source: "getSelectableTokenizeDictionaryList",
        componentType: "singleSelect",
        showOnDesignView: true,
        ifShow: "ifShowTokenizeDictionaryObjectId"
      }
    },
    //-----------输入端
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    //-----------输出端
    output: {
      textLibrary: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    baseInput: "records",
    fields: {
      data: null,
      invisible: null,
      source: "getFieldsOfTokenize",
      changesOfFields: null
    }
  },
  tokenize_en: {
    label: "分词（英文）",
    icon: "icon-a-md-translateCopy",
    ifPersistent: true,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    //-----------配置页面信息
    configs: {
      field: {
        label: "待分词字段",
        name: "field",
        source: "getSelectableAnalysisFieldsOfEn",
        value: null,
        showOnDesignView: true,

        componentType: "singleSelect"
      },
      tokenizeMethod: {
        label: "分词方法",
        name: "tokenize_method",
        source: "getTokenizeMethodOptions",
        value: null,
        showOnDesignView: true,
        componentType: "radio"
      },
      tokenizeGranularity: {
        label: "分词粒度",
        name: "tokenize_granularity",
        source: "getTokenizeGranularityOptions",
        value: null,
        showOnDesignView: true,
        componentType: "radio"
      },
      tokenizeDictionaryObjectId: {
        label: "分词词典",
        name: "tokenize_dictionary_object_id",
        value: null,
        source: "getSelectableTokenizeDictionaryList",
        componentType: "singleSelect",
        showOnDesignView: true,
        ifShow: "ifShowTokenizeDictionaryObjectId"
      }
    },
    //-----------输入端
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    //-----------输出端
    output: {
      textLibrary: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    baseInput: "records",
    fields: {
      data: null,
      invisible: null,
      source: "getFieldsOfTokenizeOfEn",
      changesOfFields: null
    }
  },
  encoding: {
    label: "编码",
    icon: "icon-a-md-label_outlineCopy",
    ifPersistent: false,
    persistent: true,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    configs: {
      analysisFields: {
        label: "待分析字段",
        name: "analysis_fields",
        source: "getSelectableAnalysisFields",
        value: null,
        hint: "文本或长文本类型字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "multiSelect",
        rule: ""
      },
      level: {
        label: "编码层级",
        name: "level",
        source: "getLevelOfEncoding",
        value: 1,
        hint: "",

        wzrningMessage: "",
        showOnDesignView: true,
        componentType: "radio",
        rule: ""
      },
      encodingRuleDictionaryId: {
        label: "编码词典",
        name: "encoding_rule_dictionary_id",
        source: "getNotEmptyEncodingDictionaryList",
        value: null,
        hint: "选择一个编码词典",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldName: "____encoding_result____",
          fieldLabel: "编码结果",
          type: 8
        }
      ]
    },
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      },
      statistics: {
        label: "编码统计",
        name: "statistics",
        outputType: "SimpleCategoryStatistics",
        group: "multiOutputGroup"
      }
    }
  },
  textReplaceModule: {
    label: "替换",
    icon: "icon-md-find_replace",
    ifPersistent: false,
    persistent: true,
    moduleStatus: 0,
    progress: 0,

    configs: {
      fields: {
        label: "待处理字段列表",
        name: "fields",
        source: "getSelectableAnalysisFields",
        value: null,
        showOnDesignView: true,
        componentType: "multiSelect"
      },
      fromStr: {
        label: "原始文本",
        name: "from_str",
        source: null,
        value: null,
        showOnDesignView: true,
        componentType: "lineTextInput"
      },
      toStr: {
        label: "替换为",
        name: "to_str",
        source: null,
        value: null,
        showOnDesignView: true,
        componentType: "lineTextInput"
      }
    },
    getConfigs: null,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  SortByFieldsModule: {
    label: "排序",
    icon: "icon-md-sort_by_alpha",
    ifPersistent: false,
    persistent: true,
    moduleStatus: 0,
    progress: 0,

    configs: {
      fields: {
        label: "依据字段列表",
        name: "fields",
        source: "getSortableFields",
        value: null,
        showOnDesignView: true,
        componentType: "multiSelect"
      },
      ifReverse: {
        label: "是否降序",
        name: "if_reverse",
        source: "getIfReverseOptions",
        value: true,
        showOnDesignView: true,
        componentType: "radio"
      }
    },
    getConfigs: null,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  removeDuplicateModule: {
    label: "去重",
    icon: "icon-riLine-scan-2-line",
    ifPersistent: false,
    persistent: true,
    moduleStatus: 0,
    progress: 0,

    configs: {
      fields: {
        label: "根据字段",
        name: "fields",
        source: "getOriginTypeOfFields",
        value: null,
        showOnDesignView: true,
        componentType: "multiSelect"
      }
    },
    getConfigs: null,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  samplingModule: {
    label: "取样",
    icon: "icon-md-colorize",
    ifPersistent: false,
    persistent: true,
    moduleStatus: 0,
    progress: 0,

    configs: {
      samplingAmount: {
        label: "取样量",
        name: "sampling_amount",
        value: 0,
        showOnDesignView: true,
        componentType: "sampleNumInput",
        sampleType: "amount",
        minValue: 0,
        maxValue: 0.99,
        precision: 2, //精度，
        step: 0.05
      }
    },
    getConfigs: null,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  filterModule: {
    label: "筛选",
    icon: "icon-riLine-filter-line",
    ifPersistent: false,
    persistent: true,

    isValid: false,
    moduleStatus: 0,
    progress: 0,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    configs: {
      expressions: {
        label: "筛选条件",
        name: "expressions",
        source: "getFilterableFields",
        options: [],
        opOptions: {
          // 文本类型
          1: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "不包含",
              value: "not_contain"
            }
          ],
          // 长文本类型
          2: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "不包含",
              value: "not_contain"
            }
          ],
          //分类文本类型
          3: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "不包含",
              value: "not_contain"
            }
          ],
          //数值类型
          4: [
            {
              label: "大于",
              value: ">"
            },
            {
              label: "小于",
              value: "<"
            },
            {
              label: "大于等于",
              value: ">="
            },
            {
              label: "小于等于",
              value: "<="
            },
            {
              label: "等于",
              value: "=="
            },
            {
              label: "不等于",
              value: "!="
            }
          ],
          // 日期类型
          5: [
            {
              label: "大于",
              value: ">"
            },
            {
              label: "小于",
              value: "<"
            },
            {
              label: "大于等于",
              value: ">="
            },
            {
              label: "小于等于",
              value: "<="
            },
            {
              label: "等于",
              value: "=="
            },
            {
              label: "不等于",
              value: "!="
            }
          ],

          // 链接类型
          6: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "不包含",
              value: "not_contain"
            }
          ],
          // 数组类型
          8: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "不包含",
              value: "not_contain"
            }
          ],
          // 类别数组
          9: [
            {
              label: "包含",
              value: "contain"
            },
            {
              label: "包含",
              value: "not_contain"
            }
          ]
        },
        cmdOptions: [
          { label: "并且", value: "&" },
          { label: "或者", value: "|" }
        ],
        value: [
          {
            field: null,
            type: null,
            op: null,
            value: null,
            compound_op: null
          }
        ],
        showOnDesignView: true,
        componentType: "filterGenerator"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  // ==============================文本分析模块===========================
  wordStatisticsModule: {
    label: "词频统计",
    icon: "icon-riLine-soundcloud-line",
    ifPersistent: false,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      analysisFields: {
        label: "分词字段",
        name: "analysis_fields",
        source: "getTokenizeTypeOfFields",
        value: null,
        showOnDesignView: true,
        componentType: "multiSelect"
      }
    },
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    output: {
      statistics: {
        label: "词频统计结果",
        name: "statistics",
        outputType: "WordsCloud",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      // source: null,
      source: "replaceWithChangesOfFields",

      changesOfFields: [
        {
          fieldName: "name",
          fieldLabel: "词语",
          type: FIELD_TYPE_TEXT_ZH
        },
        {
          fieldName: "nature",
          fieldLabel: "词性",
          type: FIELD_TYPE_CATEGORY_TEXT
        },
        {
          fieldName: "frequency",
          fieldLabel: "词频",
          type: FIELD_TYPE_NUM
        }
      ]
    }
  },
  textClusteringModule: {
    icon: "icon-a-md-bubble_chartCopy",
    label: "文本聚类",
    ifPersistent: false,
    persistent: true,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    configs: {
      analysisField: {
        label: "待分析字段",
        name: "analysis_field",
        source: "getSelectableAnalysisFields",
        value: null,
        hint: "分词结果字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      },
      method: {
        label: "聚类算法",
        name: "method",
        source: "getMethodOptionOfCluster",
        value: null,
        hint: "仅可选已训练完成的模型",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      },
      clusterNum: {
        label: "聚类数目",
        name: "cluster_num",
        source: null,
        value: 8,
        showOnDesignView: true,
        componentType: "clusterNumInput",
        minValue: 2,
        maxValue: 32,
        precision: 0
      },
      statisticsDateField: {
        label: "时间统计依据字段",
        name: "statistics_date_field",
        source: "getDateTimeTypeOfFields",
        value: null,
        showOnDesignView: false,
        componentType: "singleSelect",
        clearable: true
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldLabel: "所属簇ID",
          fieldName: "____clustering_belong_to_id____",
          type: FIELD_TYPE_NUM
        },
        {
          fieldLabel: "所属簇标签",
          fieldName: "____clustering_belong_to____",
          type: FIELD_TYPE_CATEGORY_TEXT
        }
      ]
    },
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      },
      clusters: {
        label: "类别统计",
        name: "clusters",
        outputType: "ArrayCategoryStatistics",
        group: "multiOutputGroup"
      },
      dateStatistics: {
        label: "聚类结果按时间统计",
        name: "date_statistics",
        outputType: "MultiLineCharts",
        group: "multiOutputGroup"
      }
    }
  },
  phraseExtractionModule: {
    label: "短语抽取",
    icon: "icon-md-blur_linear1",
    ifPersistent: false,
    persistent: true,
    configs: {
      analysisField: {
        label: "待分析字段",
        name: "analysis_field",
        source: "getSelectableAnalysisFields",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      },
      topicTheta: {
        label: "主题权重",
        name: "topic_theta",
        value: 0.5,
        showOnDesignView: true,
        componentType: "numberInput",
        precision: 2, //精度，
        step: 0.05, //步长
        minValue: 0,
        maxValue: 1
      }
    },
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    output: {
      statistics: {
        label: "短语列表",
        name: "statistics",
        outputType: "WordsCloud",
        group: "multiOutputGroup"
      }
    },
    baseInput: "records",
    fields: {
      data: null,
      // source: "getFieldsOfFormerNode"
      source: "replaceWithChangesOfFields",
      changesOfFields: [
        {
          fieldName: "name",
          fieldLabel: "短语名称",
          type: FIELD_TYPE_TEXT_ZH
        },
        {
          fieldName: "n",
          fieldLabel: "短语数量",
          type: FIELD_TYPE_NUM
        },
        {
          fieldName: "importance",
          fieldLabel: "重要度",
          type: FIELD_TYPE_NUM
        },
        {
          fieldName: "frequency",
          fieldLabel: "频数",
          type: FIELD_TYPE_NUM
        }
      ]
    }
  },
  textCategorizationModule: {
    label: "文本分类",
    icon: "icon-a-md-blur_offCopy",
    ifPersistent: false,
    persistent: true,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    configs: {
      analysisField: {
        label: "待分析字段",
        name: "analysis_field",
        source: "getTokenizeTypeOfFields",
        value: null,
        hint: "分词结果字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      },
      modelId: {
        label: "文本分类模型",
        name: "model_id",
        source: "getAvailableModelList",
        value: null,
        hint: "仅可选已训练完成的模型",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        languageField: "categoryLanguage",
        rule: ""
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldLabel: "分类结果",
          fieldName: "____categorization_result____",
          type: FIELD_TYPE_CATEGORY_TEXT
        }
      ]
    },
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      },
      statistics: {
        label: "类别统计",
        name: "statistics",
        outputType: "SimpleCategoryStatistics",
        group: "multiOutputGroup"
      }
    }
  },
  sentimentModule: {
    label: "情感分析",
    icon: "icon-a-md-sentiment_satisfiedCopy",
    ifPersistent: false,
    persistent: true,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    configs: {
      analysisFields: {
        label: "待分析字段",
        name: "analysis_fields",
        source: "getSelectableAnalysisFields",
        value: null,
        hint: "文本或长文本字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "multiSelect",
        rule: ""
      },
      sentimentDictionary: {
        label: "情感词典",
        name: "sentiment_dictionary",
        source: "getAvailableSentimentDictionaryList",
        value: null,
        hint: "请选择情感词典",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      }
    },
    fields: {
      data: null,
      source: "getFieldsOfSentimentAnalysis",
      changesOfFields: []
    },
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      }
    }
  },
  eventAnalysisModule: {
    label: "事件分析",
    icon: "icon-md-event_note1",
    ifPersistent: false,
    persistent: true,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    configs: {
      analysisField: {
        label: "待分析字段",
        name: "analysis_field",
        source: "getSelectableAnalysisFields",
        value: null,
        hint: "文本或长文本字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      },
      eventDictionaryId: {
        label: "事件词典",
        name: "event_dictionary_id",
        source: "getAvailableEventDictionaryList",
        value: null,
        hint: "请选择事件词典",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldName: "____events_num____",
          fieldLabel: "事件数目",
          type: FIELD_TYPE_NUM
        },
        {
          fieldName: "____events____",
          fieldLabel: "事件列表",
          type: FIELD_TYPE_EVENTS
        }
      ]
    },
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      },
      categoriesCount: {
        label: "事件分类计数",
        name: "categories_count",
        outputType: "SimpleCategoryStatistics",
        group: "multiOutputGroup"
      }
    }
  },
  topicAnalysisModule: {
    label: "主题分析",
    icon: "icon-a-md-appsCopy",
    ifPersistent: false,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      analysisField: {
        label: "分词字段",
        name: "analysis_field",
        source: "getTokenizeTypeOfFields",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      },
      topicNum: {
        label: "主题数量",
        name: "topic_num",
        source: null,
        value: null,
        topicRadio: "auto",
        showOnDesignView: true,
        componentType: "topicNumInput",
        minValue: 2,
        precision: 0,
        maxValue: 64
      },
      statisticsDateField: {
        label: "时间统计依据字段",
        name: "statistics_date_field",
        source: "getDateTimeTypeOfFields",
        value: null,
        showOnDesignView: false,
        componentType: "singleSelect",
        clearable: true
      }
    },
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      records: {
        label: "多字段文本列表",
        name: "records",
        outputType: "MultiFieldsTextList",
        group: "multiOutputGroup"
      },
      topic: {
        label: "主题-关键词",
        name: "topics",
        outputType: "TopicProbabilityList",
        group: "multiOutputGroup"
      },
      topicCount: {
        label: "主题计数",
        name: "topic_count",
        outputType: "SimpleCategoryStatistics",
        group: "multiOutputGroup"
      },
      dateStatistics: {
        label: "主题按时间统计",
        name: "date_statistics",
        outputType: "MultiLineCharts",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldName: "____best_topic____",
          fieldLabel: "概率最高主题",
          type: FIELD_TYPE_CATEGORY_TEXT
        },
        {
          fieldName: "____topic_probability_list____",
          fieldLabel: "主题概率列表",
          type: FIELD_TYPE_ARRAY
        }
      ]
    }
  },
  cooccurrenceModule: {
    label: "共现分析",
    icon: "icon-a-antOutline-radar-chartCopy",
    ifPersistent: false,
    persistent: true,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      analysisField: {
        label: "分词字段",
        name: "analysis_field",
        source: "getTokenizeTypeOfFields",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      },
      commonWordListId: {
        label: "常用词语表",
        name: "common_word_list_id",
        source: "getCommonWordListOptions",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      }
    },
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    output: {
      cooccurrence: {
        label: "共现矩阵",
        name: "cooccurrence",
        outputType: "RelationGraph",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null,
      changesOfFields: null
    }
  },
  word2VectorModule: {
    label: "词向量化",
    icon: "icon-a-md-shuffleCopy2",
    ifPersistent: false,
    persistent: true,
    configs: {
      analysisField: {
        label: "向量化字段",
        name: "analysis_field",
        source: "getTokenizeTypeOfFields",
        value: null,
        hint: "文本或长文本类型字段",
        warningMessage: "",
        showOnDesignView: true,
        options: [],
        componentType: "singleSelect",
        rule: ""
      },
      vectorSize: {
        label: "向量维度",
        name: "vector_size",
        source: null,
        value: 32,
        showOnDesignView: true,
        componentType: "numberInput",
        minValue: 2,
        maxValue: 128,
        step: 1,
        precision: 0
      }
    },
    getConfigs: null,
    input: {
      records: {
        label: "多字段文本列表",
        name: "records",
        requiredType: ["MultiFieldsTextList"],
        group: "singleInputGroup"
      }
    },
    baseInput: "records",
    output: {
      words: {
        label: "词向量化结果",
        name: "words",
        outputType: "VectorCollection",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: [
        {
          fieldName: "word",
          fieldLabel: "词语",
          type: FIELD_TYPE_TEXT_ZH
        },
        {
          fieldName: "vector",
          fieldLabel: "向量",
          type: FIELD_TYPE_ARRAY
        },
        {
          fieldName: "nature",
          fieldLabel: "词性",
          type: FIELD_TYPE_CATEGORY_TEXT
        },
        {
          fieldName: "name",
          fieldLabel: "名",
          type: FIELD_TYPE_TEXT_ZH
        }
      ]
    }
  },
  similarityModule: {
    label: "相似分析",
    icon: "icon-a-md-compare_arrows21",
    ifPersistent: false,
    persistent: true,

    configs: {
      commonWordListId: {
        label: "常用词语表",
        name: "common_word_list_id",
        source: "getCommonWordListOptions",
        value: null,
        showOnDesignView: true,
        componentType: "singleSelect"
      }
    },
    getConfigs: null,
    input: {
      vectors: {
        label: "向量集合",
        name: "vectors",
        requiredType: ["VectorCollection"],
        group: "singleInputGroup"
      }
    },
    baseInput: "vectors",
    output: {
      similarity: {
        label: "相似度列表",
        name: "similarity",
        outputType: "SimilarityTable",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      source: "addChangesOfFields",
      changesOfFields: []
    }
  },
  // ============================可视化模块==============================
  tableModule: {
    //未写完
    label: "表格",
    icon: "icon-antOutline-table",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "表格",
        componentType: "lineTextInput",
        showOnDesignView: true
      }
    },
    input: {
      wordsCloud: {
        label: "词云/表格数据",
        name: "words_cloud",
        requiredType: [
          "WordsCloud",
          "MultiFieldsTextList",
          "SimpleCategoryStatistics",
          "ArrayCategoryStatistics",
          "TopicProbabilityList",
          "RelationGraph",
          "SimilarityTable"
        ],
        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Table",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  barChartModule: {
    label: "柱状图",
    icon: "icon-antOutline-bar-chart",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "柱状图",
        componentType: "lineTextInput",
        showOnDesignView: true
      }
    },
    input: {
      categoryStatistics: {
        label: "类别统计数据",
        name: "category_statistics",
        requiredType: ["SimpleCategoryStatistics", "ArrayCategoryStatistics"],
        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Chart",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  pieChartModule: {
    label: "饼状图",
    icon: "icon-antOutline-pie-chart",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "饼状图",
        componentType: "lineTextInput",
        showOnDesignView: true
      }
    },
    input: {
      categoryStatistics: {
        label: "类别统计数据",
        name: "category_statistics",
        requiredType: ["SimpleCategoryStatistics", "ArrayCategoryStatistics"],
        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Chart",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  lineChartModule: {
    //未写完
    label: "折线图",
    icon: "icon-antOutline-line-chart",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "折线图",
        componentType: "lineTextInput",
        showOnDesignView: true
      }
    },
    input: {
      arrayCategoryStatistics: {
        label: "类别统计数据",
        name: "array_category_statistics",
        // requiredType: ["ArrayCategoryStatistics", "MultiLineCharts"],
        requiredType: ["MultiLineCharts"],

        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Chart",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  forceDirectedModule: {
    //未写完
    label: "力导向图",
    icon: "icon-tp_force",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "力导向图",
        componentType: "lineTextInput",
        showOnDesignView: true
      }
    },
    input: {
      relationGraph: {
        label: "关系图数据",
        name: "relation_graph",
        requiredType: ["RelationGraph"],
        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Chart",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  wordCloudGraphModule: {
    label: "词云图",
    icon: "icon-antOutline-cloud",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    configs: {
      title: {
        label: "图表标题",
        name: "title",
        source: null,
        value: "词云图",
        componentType: "lineTextInput",
        showOnDesignView: true
      },
      viewStyle: {
        label: "显示样式",
        name: "view_style",
        source: "getViewStyleOfWordCloud",
        value: null,
        componentType: "singleSelect",
        showOnDesignView: true
      },
      shapePictureId: {
        label: "词云图图片",
        name: "shape_picture_id",
        source: "getShapePictureOptions",
        value: null,
        componentType: "pictureSelect",
        showOnDesignView: false,
        clearable: true
      }
    },
    input: {
      wordsCloud: {
        label: "词云/表格数据",
        name: "words_cloud",
        requiredType: ["WordsCloud"],
        group: "singleInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "Chart",
        group: "multiOutputGroup"
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    }
  },
  verticalLayoutModule: {
    label: "垂直布局",
    icon: "icon-hengxiangbuju",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: true,
    configs: {
      sortList: {
        label: "排列顺序",
        name: "sort_list",
        source: "getFormerNodesList",
        value: [],
        componentType: "draggableList",
        showOnDesignView: false
      }
    },
    fields: {
      data: null,
      invisible: null,
      source: null
    },
    baseInput: "visual_module_1",
    input: {
      visualModule: {
        label: "可视化模块",
        name: "visual_module_1",
        requiredType: ["HorizontalLayout", "Chart", "Table"],
        group: "multiInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        requiredType: ["Layout"],
        group: "multiOutputGroup"
      }
    }
  },
  horizontalLayoutModule: {
    icon: "icon-shuxiangbuju",
    label: "水平布局",
    ifPersistent: true,
    persistent: false,

    moduleStatus: 0,
    progress: 0,
    isValid: false,
    baseInput: "visual_module",
    input: {
      visualModule: {
        label: "可视化模块模块",
        name: "visual_module",
        requiredType: ["VerticalLayout", "Chart", "Table"],
        group: "multiInputGroup"
      }
    },
    output: {
      visualModuleResult: {
        label: "可视化模块结果",
        name: "visual_module_result",
        outputType: "HorizontalLayout",
        group: "multiOutputGroup"
      }
    },
    configs: {
      sortList: {
        label: "排列顺序",
        name: "sort_list",
        source: "getFormerNodesList",
        value: [],
        componentType: "draggableList",
        showOnDesignView: false
      }
    },
    configSource: "getConfigsOfHorizontalLayout",
    fields: {
      data: null,
      invisible: null,
      changesOfFields: null
    }
  }
};

const INPUT_LIBRARY_MODULE = 101; //加载文本库
const INPUT_TEXT_MODULE = 102; //输入文本
const OUTPUT_LIBRARY_MODULE = 103; //输出文本库
//===================
const DEDUPLICATION_MODULE = 1001; //去重
const REPLACE_MODULE = 1002; //替换
const SORT_MODULE = 1003; //排序
const FILTER_MODULE = 1004; //筛选
const FUNCTION_MODULE = 1009; //函数
const SAMPLING_MODULE = 1005; //取样
const DIVIDE_MODULE = 207; //分割
const AGGREGATION_MODULE = 1006; //聚合
const UNION_MODULE = 1010; //联合
const MERGE_MODULE = 1008; // 合并
const ENCODE_MODULE = 8; //编码
const TOKENIZE_MODULE = 1007; //分词
const TOKENIZE_MODULE_EN = 10001; //TODO英文分词

const VECTORIZATION_MODULE = 7; //向量化
//====================
const WORD_FREQUENCY_STATISTICS_MODULE = 1; //词频统计
const ENTITY_RECOGNITION_MODULE = 302; //实体识别
const TEXT_CLASSIFICATION_MODULE = 6; //文本分类
const TEXT_CLUSTERING_MODULE = 4; //文本聚类
const SENTIMENT_ANALYSIS_MODULE = 2; //情感分析
const THEMATIC_MODULE = 3; //主题分析
const KEYPHRASE_EXTRCTION_MODULE = 9; //短语抽取
const SIMILARITY_ANALYSIS_MODULE = 12; //相似分析
const CO_OCCURRENCE_ANALYSIS_MODULE = 11; //共现分析
const EVENT_ANALYSIS_MODULE = 10; //事件分析
//===============
const TABLE_MODULE = 401; //表格
const BAR_CHART_MODULE = 402; //柱状图
const LINE_CHART_MODULE = 403; //折线图
const PIE_CHART_MODULE = 404; //饼状图
const WORD_CLOUD_MODULE = 405; //词云图
const SCATTER_CHART_MODULE = 406; // 散点图
const FORCE_DIRECTED_MODULE = 407; //力导向图
const MAP_MODULE = 408; //地图
const TEXT_MODULE = 409; //文本
const LAYOUT_MODULE = 410; //布局
const VERTICAL_LAYOUT_MODULE = 501; //垂直布局
const HORIZONTAL_LAYOUT_MODULE = 502; //水平布局

const MODULE_LIST = [
  //=====================输入输出模块=============
  {
    label: "输入输出",
    icon: "icon-st-transport",
    children: [
      // ==========example begin=============
      {
        label: "读取文本库",
        icon: "icon-antOutline-read",
        name: "readTextLibraryModule",
        span: 12,
        value: INPUT_LIBRARY_MODULE
      },
      // ==========example end=============
      // {
      //   label: "输入文本",
      //   icon: "icon-riLine-input-cursor-move",
      //   value: INPUT_TEXT_MODULE
      // },
      {
        label: "输出文本库",
        name: "saveAsTextLibraryModule",
        icon: "icon-antOutline-save",
        span: 12,
        value: OUTPUT_LIBRARY_MODULE
      }
    ]
  },
  //=================数据处理模块==============
  {
    label: "数据处理",
    icon: "icon-a-md-swap_vertical_circleCopy",
    children: [
      {
        label: "去重",
        icon: "icon-riLine-scan-2-line",
        name: "removeDuplicateModule",
        span: 8,
        value: DEDUPLICATION_MODULE
      },
      {
        label: "替换",
        icon: "icon-md-find_replace",
        name: "textReplaceModule",
        span: 8,
        value: REPLACE_MODULE
      },
      {
        label: "排序",
        icon: "icon-md-sort_by_alpha",
        name: "SortByFieldsModule",
        span: 8,
        value: SORT_MODULE
      },
      {
        label: "筛选",
        icon: "icon-riLine-filter-line",
        name: "filterModule",
        span: 8,
        value: FILTER_MODULE
      },
      // { label: "函数", icon: "icon-riLine-functions", value: FUNCTION_MODULE },
      {
        label: "取样",
        icon: "icon-md-colorize",
        name: "samplingModule",
        span: 8,
        value: SAMPLING_MODULE
      },
      // { label: "分割", icon: "icon-md-border_inner", value: DIVIDE_MODULE },
      // {
      //   label: "聚合",
      //   icon: "icon-riLine-keyboard-line",
      //   value: AGGREGATION_MODULE
      // },
      // { label: "联合", icon: "icon-riLine-link", value: UNION_MODULE },
      // { label: "合并", icon: "icon-md-merge_type", value: MERGE_MODULE },
      {
        label: "编码",
        icon: "icon-a-md-label_outlineCopy",
        name: "encoding",
        span: 8,
        value: ENCODE_MODULE
      },
      // ==========example begin=============
      {
        label: "分词",
        name: "tokenize",
        icon: "icon-a-md-translateCopy",
        span: 8,
        value: TOKENIZE_MODULE
      },
      {
        label: "分词（英文）",
        name: "tokenize_en",
        icon: "icon-a-md-translateCopy",
        span: 14,
        value: TOKENIZE_MODULE_EN
      }
    ]
  },
  // ===============文本分析模块==============
  {
    label: "文本分析",
    icon: "icon-riLine-input-method-line",
    children: [
      {
        label: "词频统计",
        icon: "icon-riLine-soundcloud-line",
        name: "wordStatisticsModule",
        span: 12,
        value: WORD_FREQUENCY_STATISTICS_MODULE
      },
      // {
      //   label: "实体识别",
      //   icon: "icon-a-md-publicCopy",
      //   value: ENTITY_RECOGNITION_MODULE
      // },
      // {
      //   label: "文本分类",
      //   icon: "icon-a-md-blur_offCopy",
      //   name: "textCategorizationModule",
      //   span: 12,
      //   value: TEXT_CLASSIFICATION_MODULE
      // },
      // {
      //   label: "文本聚类",
      //   icon: "icon-a-md-bubble_chartCopy",
      //   name: "textClusteringModule",
      //   span: 12,
      //   value: TEXT_CLUSTERING_MODULE
      // },
      {
        label: "情感分析",
        icon: "icon-a-md-sentiment_satisfiedCopy",
        name: "sentimentModule",
        span: 12,
        value: SENTIMENT_ANALYSIS_MODULE
      },
      {
        label: "主题分析",
        icon: "icon-a-md-appsCopy",
        name: "topicAnalysisModule",
        span: 12,
        value: THEMATIC_MODULE
      },
      {
        label: "短语抽取",
        icon: "icon-md-blur_linear1",
        name: "phraseExtractionModule",
        span: 12,
        value: KEYPHRASE_EXTRCTION_MODULE
      },
      {
        label: "相似分析",
        icon: "icon-a-md-compare_arrows21",
        name: "similarityModule",
        span: 12,
        value: SIMILARITY_ANALYSIS_MODULE
      },
      {
        label: "共现分析",
        icon: "icon-a-antOutline-radar-chartCopy",
        name: "cooccurrenceModule",
        span: 12,
        value: CO_OCCURRENCE_ANALYSIS_MODULE
      },
      // {
      //   label: "事件分析",
      //   icon: "icon-md-event_note1",
      //   name: "eventAnalysisModule",
      //   span: 12,
      //   value: EVENT_ANALYSIS_MODULE
      // },
      {
        label: "词向量化",
        icon: "icon-a-md-shuffleCopy2",
        name: "word2VectorModule",
        span: 12,
        value: VECTORIZATION_MODULE
      }
    ]
  },
  //=================可视化===================
  {
    label: "可视化",
    icon: "icon-antOutline-dashboard",
    children: [
      {
        label: "表格",
        icon: "icon-antOutline-table",
        name: "tableModule",
        span: 12,
        value: TABLE_MODULE
      },
      {
        label: "柱状图",
        icon: "icon-antOutline-bar-chart",
        name: "barChartModule",
        span: 12,
        value: BAR_CHART_MODULE
      },
      {
        label: "折线图",
        icon: "icon-antOutline-line-chart",
        name: "lineChartModule",
        span: 12,
        value: LINE_CHART_MODULE
      },
      {
        label: "饼状图",
        icon: "icon-antOutline-pie-chart",
        name: "pieChartModule",
        span: 12,
        value: PIE_CHART_MODULE
      },
      {
        label: "词云图",
        icon: "icon-antOutline-cloud",
        name: "wordCloudGraphModule",
        span: 12,
        value: WORD_CLOUD_MODULE
      },
      // {
      //   label: "散点图",
      //   icon: "icon-a-antOutline-dot-chart2",
      //   value: SCATTER_CHART_MODULE
      // },
      {
        label: "力导向图",
        icon: "icon-tp_force",
        name: "forceDirectedModule",
        span: 12,
        value: FORCE_DIRECTED_MODULE
      },
      // { label: "地图", icon: "icon-a-fasfa-globe", value: MAP_MODULE },
      // { label: "文本", icon: "icon-md-text_fields", value: TEXT_MODULE },
      // { label: "布局", icon: "icon-antOutline-layout", value: LAYOUT_MODULE },
      {
        label: "垂直布局",
        icon: "icon-hengxiangbuju",
        name: "verticalLayoutModule",
        span: 12,
        value: VERTICAL_LAYOUT_MODULE
      },
      {
        label: "水平布局",
        icon: "icon-shuxiangbuju",
        name: "horizontalLayoutModule",
        span: 12,
        value: HORIZONTAL_LAYOUT_MODULE
      }
    ]
  }
];
const moduleInfoVersion = "2.0.3";
// 2.0.3：分词模块配置改动
// 2.0.2：垂直布局模块和水平布局模块加入排列顺序配置项
// 2.0.1:词云图模块加入词云图图片配置项

export { moduleInfo, MODULE_LIST, moduleInfoVersion };

<template>
  <div class="table-wrapper list-table">
    <el-table :data="data" header-cell-class-name="list-table-header">
      <el-table-column prop="name" label="名称">
        <template #default="scope">
          <div class="library-name" @click="goToLiraryPage(scope.row)">
            <i class="iconfont icon-textLibrary list-item-icon"></i>
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="创建人"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  data: Array
});
const router = useRouter();

//跳转文本库页面
const goToLiraryPage = (row) => {
  router.push({
    name: "DataRecordIndex",
    params: {
      textLibraryId: row.id
    }
  });
};
onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";

.table-wrapper {
  .library-name {
    @include pointer();
    @include no-selectable();
    .icon-textLibrary {
      color: $theme-color;
    }
  }
}
</style>

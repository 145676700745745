<!-- 
  最近分析表格组件
  所需参数： data:表格内容
-->
<template>
  <div class="analysis-table-wrapper list-table">
    <el-table
      :data="data"
      style="width: 100%"
      header-cell-class-name="list-table-header"
    >
      <el-table-column prop="name" label="名称" min-width="200">
        <template #default="scope">
          <TextEditor
            :editable="false"
            icon="icon-fenxi"
            :text="scope.row.name"
            @go="handleClickName(scope.row)"
            :font-color="scope.row.status == 5 ? '#ec2411' : ''"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="创建人" />
      <el-table-column prop="updateTime" label="更新时间" width="180" />
      <el-table-column prop="status" label="状态" width="200" align="center">
        <template #default="scope">
          <!-- TODO 待校验 -->
          <StatusTag
            :type="statusMap[scope.row.status].type"
            :label="statusMap[scope.row.status].label"
          ></StatusTag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import StatusTag from "@/components/base/StatusTag.vue";
import { defineProps } from "vue";
import { statusMap } from "@/constant/analysis/status";
import { CONTENT_TYPE } from "@/constant/analysis";
import { useRouter } from "vue-router";
import TextEditor from "@/components/base/TextEditor.vue";
import { ElMessage, ElMessageBox } from "element-plus";

const props = defineProps({
  data: {
    type: Array
  }
});
const router = useRouter();

const handleClickName = (row) => {
  goToAnalysisModelPage(row);
};
//跳转分析模型页面
const goToAnalysisModelPage = (row) => {
  if (row.status == 4) {
    ElMessage.warning("无法跳转到已失效模型");
    return;
  }
  router.push({
    name: "AnalysisModel",
    query: {
      analysisId: row.id
    }
  });
};
// let statusMap = {
//   0: { type: "info", label: "已创建", value: 0 },
//   1: { type: "warning", label: "进行中", value: 1 },
//   2: { type: "success", label: "成功", value: 2 },
//   3: { type: "danger", label: "异常", value: 3 },
//   4: { type: "info", label: "已失效", value: 4 },
//   5: { type: "success", label: "成功", value: 5 }
// }; // 状态map
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
</style>

<template>
  <div class="home-wrapper">
    <div class="left-container">
      <div class="data-box">
        <ProjectDataBox :projectDataStatus="projectDataStatus"></ProjectDataBox>
      </div>
      <div class="latest-list-box">
        <el-tabs v-model="activeTab">
          <el-tab-pane
            v-for="tabItem in tabList"
            :key="tabItem.name"
            :label="tabItem.label"
            :name="tabItem.name"
          >
            <template #label>
              <span>
                <i :class="['iconfont', tabItem.icon, 'tab-icon']"></i>
                <span>{{ tabItem.label }}</span>
              </span>
            </template>
            <div>
              <component
                :is="tabItem.component"
                :data="projectDataStatus[tabItem.dataProp]"
              ></component>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="right-container">
      <QuickNavigation @refresh="getProjectInfo"></QuickNavigation>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, shallowRef, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";
import ProjectDataBox from "./components/ProjectDataBox.vue";
import QuickNavigation from "./components/QuickNavigation.vue";
import LatestAnalysisTable from "./components/LatestAnalysisTable.vue";
import LatestTextLibraryTable from "./components/LatestTextLibraryTable.vue";
import { useRouter } from "vue-router";

const emits = defineEmits(["setBreadcrumbList"]);
const router = useRouter();

// 项目概览信息
const projectDataStatus = ref({
  baseInfoList: [
    {
      text: "文本库",
      num: 0
    },
    {
      text: "分析",
      num: 0
    }
  ],
  latestAnalysisList: [],
  latestTextLiabraryList: []
});

// 获取项目概览信息
const getProjectInfo = async () => {
  let url = "/index/getInfo";
  let res = await httpPost(url);
  if (res.code == 0) {
    projectDataStatus.value.baseInfoList = res.data.infoCountList;
    projectDataStatus.value.latestTextLiabraryList = res.data.lastLibraryList;
    projectDataStatus.value.latestAnalysisList = res.data.lastAnalysisList;
  }
};
const setBreadcrumbList = () => {
  const breadcrumbList = [
    {
      title: "首页",
      to: {
        name: "HomeIndex",
        query: {}
      }
    }
  ];
  emits("setBreadcrumbList", breadcrumbList);
};
setBreadcrumbList(); // setup立即执行；
onMounted(() => {
  getProjectInfo();
});

// 最近列表
const LATEST_TEXT_LIBRARY = 0;
const LATEST_ANALYSIS = 1;
const activeTab = ref(LATEST_TEXT_LIBRARY);
const tabList = ref([
  {
    icon: "icon-textLibrary",
    label: "最近的文本库",
    name: LATEST_TEXT_LIBRARY,
    component: shallowRef(LatestTextLibraryTable),
    dataProp: "latestTextLiabraryList"
  },
  {
    icon: "icon-fenxi",
    label: "最近的分析",
    name: LATEST_ANALYSIS,
    component: shallowRef(LatestAnalysisTable),
    dataProp: "latestAnalysisList"
  }
]);
</script>

<style lang="scss" scoped>
.home-wrapper {
  $right-container-width: 280px;
  $data-box-height: 100px;
  display: flex;
  min-height: 100%;

  .left-container {
    flex: 1;
    width: 100%;
    // background: grey;
    height: 100%;
    margin-right: 20px;
    .data-box {
      width: 100%;
      height: $data-box-height;
      margin-bottom: 40px;
    }
    .latest-list-box {
      .tab-icon {
        margin-right: 8px;
      }
    }
  }
  .right-container {
    width: $right-container-width;
    height: 100%;
  }
}
</style>

const WORD_FREQUENCY_STATISTICS = 1; // 词频统计
const ENTITY_RECOGNITION = 2; // 实体识别
const TEXT_CLASSIFICATION = 3; // 分类文本
const TEXT_CLUSTERING = 4; // 文本聚类
const SENTIMENT_ANALYSIS = 5; // 情感分析
const SUBJECT_ANALYSIS = 6; // 主题分析
const PHRASE_EXTRACTION = 7; // 短语抽取
const SIMILARITY_ANALYSIS = 8; // 相似分析
const COOCCURRENCE_ANALYSIS = 9; // 共现分析
const EVENT_ANALYSIS = 10; // 事件分析
const AUTOMATIC_CODING = 11; // 自动编码
const CUSTOMIZE = 12; // 自定义

export const ANALYSIS_TEMPALTE = {
  WORD_FREQUENCY_STATISTICS: {
    value: 1,
    label: "词频统计",
    icon: "icon-cipintongji",
    color: "#ed6c11",
    tip: "从文本中提取信息的基本方法，通过计算每个单词在文本中出现的频率来了解文本的特征。"
  },
  ENTITY_RECOGNITION: {
    value: 2,
    label: "实体识别",
    icon: "icon-a-shitishibiedaiyanse",
    color: "rgb(166, 134, 186)",
    tip: ""
  },
  // TEXT_CLASSIFICATION: {
  //   value: 3,
  //   label: "文本分类",
  //   icon: "icon-md-blur_off",
  //   color: "#067707",
  //   tip: "通过训练模型来识别文本中的特征并将其映射到预定义的类别中。"
  // },
  // TEXT_CLUSTERING: {
  //   value: 4,
  //   label: "文本聚类",
  //   icon: "icon-md-bubble_chart",
  //   color: "#389bca",
  //   tip: "是一种将文本数据按照相似性进行分组的机器学习技术。"
  // },
  SENTIMENT_ANALYSIS: {
    value: 5,
    label: "情感分析",
    icon: "icon-md-sentiment_satisfied",
    color: "#e94743",
    tip: "用于自动识别和提取文本中的情感倾向和情感极性。"
  },
  SUBJECT_ANALYSIS: {
    value: 6,
    label: "主题分析",
    icon: "icon-md-apps",
    color: "red",
    tip: "将文本表示成主题的分布，每个主题表示为单词的分布，从文本中识别和提取潜在主题。"
  },
  PHRASE_EXTRACTION: {
    value: 7,
    label: "短语抽取",
    icon: "icon-md-blur_linear",
    color: "#d78b10",
    tip: "用于从文本中提取有意义的短语，例如词组或习惯用语。"
  },
  SIMILARITY_ANALYSIS: {
    value: 8,
    label: "相似分析",
    icon: "icon-a-md-compare_arrows2",
    color: "#4caa08",
    tip: "将文本中的每个单词表示为一个向量，通过计算词向量之间的相似度来衡量单词之间的语义相似度。"
  },
  COOCCURRENCE_ANALYSIS: {
    value: 9,
    label: "共现分析",
    icon: "icon-a-antOutline-radar-chart1",
    color: "#389bca",
    tip: "用于研究文本中不同词语之间的共现关系及其统计特征。"
  },
  // EVENT_ANALYSIS: {
  //   value: 10,
  //   label: "事件分析",
  //   icon: "icon-a-farfa-lightbulb",
  //   color: "#389bca"
  // },
  AUTOMATIC_CODING: {
    value: 11,
    label: "自动编码",
    icon: "icon-md-public",
    color: "#e94743"
  },
  CUSTOMIZE: {
    value: 12,
    label: "自定义",
    icon: "icon-mb-plus-o",
    color: "#ed6c11"
  }
  // DEFAULT: {
  //   value: ,
  //   icon: "icon-cipintongji",
  //   color: "#ed6c11"
  // }
};
const getAnalysisTemplateMap = () => {
  let labelMap = {};
  for (let key of Object.keys(ANALYSIS_TEMPALTE)) {
    if (ANALYSIS_TEMPALTE[key].value) {
      labelMap[ANALYSIS_TEMPALTE[key].value] = ANALYSIS_TEMPALTE[key];
    }
  }
  return labelMap;
};

export const ANALYSIS_TEMPALTE_MAP = getAnalysisTemplateMap();
const getAnalysisTemplateLabelMap = () => {
  let labelMap = {};
  for (let key of Object.keys(ANALYSIS_TEMPALTE)) {
    if (ANALYSIS_TEMPALTE[key].value) {
      labelMap[ANALYSIS_TEMPALTE[key].value] = ANALYSIS_TEMPALTE[key].label;
    }
  }
  return labelMap;
};
export const ANALYSIS_TEMPALTE_LABEL_MAP = getAnalysisTemplateLabelMap();

const ANALYSIS_TEMPALTE_LIST = {
  1: "词频统计",
  2: "实体识别",
  3: "文本分类",
  4: "文本聚类",
  5: "情感分析",
  6: "主题分析",
  7: "短语抽取",
  8: "相似分析",
  9: "共现分析",
  10: "事件分析",
  11: "自动编码",
  12: "自定义"
};
const ANALYSIS_TEMPALTE_ICON = {};
// 词频统计
ANALYSIS_TEMPALTE_ICON[WORD_FREQUENCY_STATISTICS] = {
  icon: "icon-cipintongji",
  color: "#ed6c11"
};
// 实体识别
ANALYSIS_TEMPALTE_ICON[ENTITY_RECOGNITION] = {
  icon: "icon-a-shitishibiedaiyanse",
  color: "rgb(166, 134, 186)"
};
// 文本分类
ANALYSIS_TEMPALTE_ICON[TEXT_CLASSIFICATION] = {
  icon: "icon-md-blur_off",
  color: "#067707"
};
// 文本聚类
ANALYSIS_TEMPALTE_ICON[TEXT_CLUSTERING] = {
  icon: "icon-md-bubble_chart",
  color: "#389bca"
};
// 情感分析
ANALYSIS_TEMPALTE_ICON[SENTIMENT_ANALYSIS] = {
  icon: "icon-md-sentiment_satisfied",
  color: "#e94743"
};
// 主题分析
ANALYSIS_TEMPALTE_ICON[SUBJECT_ANALYSIS] = {
  icon: "icon-md-apps",
  color: "red"
};
// 短语抽取
ANALYSIS_TEMPALTE_ICON[PHRASE_EXTRACTION] = {
  icon: "icon-md-blur_linear",
  color: "#d78b10"
};
// 相似分析
ANALYSIS_TEMPALTE_ICON[SIMILARITY_ANALYSIS] = {
  icon: "icon-a-md-compare_arrows2",
  color: "#4caa08"
};
// 共现分析
ANALYSIS_TEMPALTE_ICON[COOCCURRENCE_ANALYSIS] = {
  icon: "icon-a-antOutline-radar-chart1",
  color: "#389bca"
};
// 事件分析
ANALYSIS_TEMPALTE_ICON[EVENT_ANALYSIS] = {
  icon: "icon-a-farfa-lightbulb",
  color: "#389bca"
};
// 自动编码
ANALYSIS_TEMPALTE_ICON[AUTOMATIC_CODING] = {
  icon: "icon-md-public",
  color: "#e94743"
};
// 自定义
ANALYSIS_TEMPALTE_ICON[CUSTOMIZE] = {
  icon: "icon-mb-plus-o",
  color: "#ed6c11"
};

export {
  ANALYSIS_TEMPALTE_ICON,
  WORD_FREQUENCY_STATISTICS,
  ENTITY_RECOGNITION,
  TEXT_CLASSIFICATION,
  TEXT_CLUSTERING,
  SENTIMENT_ANALYSIS,
  SUBJECT_ANALYSIS,
  PHRASE_EXTRACTION,
  SIMILARITY_ANALYSIS,
  COOCCURRENCE_ANALYSIS,
  EVENT_ANALYSIS,
  AUTOMATIC_CODING,
  CUSTOMIZE,
  ANALYSIS_TEMPALTE_LIST
};

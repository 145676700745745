<!-- 
  快速帮助导航组件
  所需参数： navList：导航的label和icon
            helpList：帮助信息文本
 -->
<template>
  <div class="quick-navigation-wrapper">
    <div class="quick-nav-box">
      <div class="title-text">快速创建</div>
      <ul class="option-btn-box">
        <li
          v-for="navItem in navList"
          class="option-btn"
          :key="navItem.label"
          @click="navItem.handleClick"
        >
          <span :class="['iconfont', navItem.icon, 'nav-icon']"></span>

          <span class="nav-text">{{ navItem.label }}</span>
        </li>
      </ul>
    </div>
    <div class="help-text-box">
      <div class="title-text">使用帮助</div>
      <ul>
        <li class="help-text" v-for="helpItem in helpList" :key="helpItem.text">
          <el-link href="" @click="helpItem.handleClick">
            {{ helpItem.text }}
          </el-link>
        </li>
      </ul>
    </div>
  </div>
  <TextLibraryDialogCreate
    ref="textLibraryDLGCreateRef"
    @submit="refreshParent"
  />
</template>

<script setup>
import TextLibraryDialogCreate from "../../textLibrary/components/TextLibraryDialogCreate.vue";
import { defineProps, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  //TODO 需要替换连接
  helpList: {
    default: [
      // {
      //   text: "新手引导",
      //   llink: "/index"
      // },
      {
        text: "怎么自定义分析模型?",
        link: "https://help.ringdata.com/project/wenxi/detail/10459",
        handleClick: () => {
          window.open("https://help.ringdata.com/project/wenxi/detail/10459");
        }
      }
    ]
  }
});
const emits = defineEmits(["refresh"]);
const router = useRouter();
const navList = ref([
  {
    icon: "icon-a-kuaisuchuangjian-wenbenkuceshi",
    label: "新建文本库",
    handleClick: () => {
      handleClickCreateTextLibrary();
    }
  },
  {
    icon: "icon-a-kuaisuchuangjianfenxishouye",
    label: "新建分析",
    handleClick: () => {
      handleClickCreateAnalysis();
    }
  }
]);
// console.log("props", props.quickNavList);
const textLibraryDLGCreateRef = ref(null);
const handleClickCreateTextLibrary = () => {
  textLibraryDLGCreateRef.value.openDLG();
};
// 点击创建分析
const handleClickCreateAnalysis = () => {
  let params = {
    folderId: 0
  };
  router.push({
    name: "AnalysisCreate",
    query: params
  });
};
const refreshParent = () => {
  emits("refresh");
};
</script>

<style lang="scss" scoped>
.quick-navigation-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  background-color: $main-page-background;
  @mixin title-text-style {
    font-size: $main-title-font-size;
    font-family: SourceHanSansSC-bold;
    color: $main-title-text-color;
    font-weight: $main-font-weight;
  }
  .quick-nav-box {
    min-height: 180px;
    .title-text {
      @include title-text-style();
      margin-bottom: 32px;
    }
    .option-btn-box {
      display: flex;
      align-items: center;
      .option-btn {
        margin-right: 20px;
        width: 75px;
        @include pointer($theme-color);

        span {
          display: block;
        }
        .nav-icon {
          font-size: 28px;
          margin-bottom: 18px;
        }
        .nav-text {
          margin-bottom: 20px;
        }
      }
    }
  }
  .help-text-box {
    .title-text {
      @include title-text-style();
      margin-bottom: 20px;
    }
    .help-text {
      color: $main-text-color;
      margin-bottom: 23px;
    }
  }
}
</style>
